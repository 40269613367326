@import '../../../styles/_variables.scss';

.internalDocumentContainer {
  position: relative;
  @media only screen and (max-width: $iphone-width) {
    & > div:first-of-type {
      button {
        font-size: 0;
      }
      button > span {
        font-size: 18px;
        margin-right: 0;
      }
    }
  }
}
