@import './_variables.scss';

.box {
  position: relative;
  margin-bottom: 1.5rem;
  border: 0;
  border-radius: $border-radius;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  &.box-transparent {
    box-shadow: none;
  }

  .box-dark {
    background-color: rgba(0, 0, 0, 0.03);
  }
}

// put color in .box-default, so .bg-xxx can overrides box color easily
.box-default {
  background-color: $card-bg;
}

.box-header,
.box-heading {
  padding: $card-spacer-y $card-spacer-x;
  border-bottom: 1px solid transparent;
  border-radius: ($border-radius - 1) ($border-radius - 1) 0 0;
  font-weight: $headings-font-weight;
  // text-transform: uppercase;
}

.box-header {
  position: relative;
  &.logo-company {
    display: flex;
    align-items: center;
  }
  &.advance-payment {
    display: flex;
    justify-content: space-between;
  }
  &.bill-report {
    .title {
      display: inline-block;
      border: 1px solid #367fa9;
      border-radius: 3px;
      padding: 5px 15px;
      margin-left: 20px;
    }
  }
  &.report {
    .options-group {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
  &.timesheet {
    display: flex;
    justify-content: space-between;
  }
  &.highlight {
    font-weight: 600;
  }
  .budget-remain-label {
    border: solid 1px var(--primary);
    border-radius: 3px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 30px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    &.warning {
      border-color: #ffbb00;
    }
    &.emergency {
      border-color: rgb(225, 0, 80);
    }
  }
}

.box-toolbar {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;

  a {
    color: $text-muted;

    .material-icons {
      font-size: $font-size-base;
    }
  }
}

.box-divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 0 $card-spacer-x;
}

.box-body {
  padding: $card-spacer-x;

  .highlight-indicators {
    .title {
      color: #0073b7;
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline;
    }
    .highlight {
      font-weight: 600;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .box-header {
    &.report {
      .options-group {
        position: relative;
        margin: auto;
      }
    }
    .budget-remain-label {
      position: relative;
    }
  }
  .box-body {
    padding: 10px;
  }
}
