@import '../../styles/_variables.scss';

.title,
.subtitle {
  font-weight: 400;
  color: $user-primary-color;
  text-align: center;
  margin: 0;
}

.subtitle {
  text-align: start;
  min-width: 400px;
}
