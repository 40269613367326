.statusCell {
  padding: 8px;
  gap: 4px;
  border-radius: 6px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border: 1px solid black;
  color: black;
  margin: 2px;
}

.notArchived,
.Published,
.notDeleted,
.Active,
.Not-Archived,
.Approved,
.Not-Deleted {
  background: #f0fff4;
  border: 1px solid #38a169;
  color: #38a169;
}

.Pending {
  border: 1px solid #3182ce;
  background: #ebf8ff;
  color: #3182ce;
}

.Rejected {
  border: 1px solid #718096;
  background: #f7fafc;
  color: #718096;
}

.Sent-to-the-partner,
.archived,
.admin,
.Inactive,
.external,
.Not-Active,
.Archived {
  background: #fffff0;
  border: 1px solid #d69e2e;
  color: #d69e2e;
}

.Annual-vacation,
.Destroyed,
.Denied,
.deleted,
.Deleted {
  border: 1px solid #e53e3e;
  background: #fed7d7;
  color: #e53e3e;
}

.user,
.internal {
  background: #ebf8ff;
  border: 1px solid #3182ce;
  color: #3182ce;
}

.Technology {
  background: lightgrey;
  border: 1px solid darkgray;
  color: black;
  margin-right: 2px;
}
