@import '../../../styles/_variables.scss';

.timesheet-board-page {
  .select-group {
    margin-bottom: 20px;
  }
  .timesheet-form {
    .options-sub {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .timesheet-button {
        margin: 10px;
      }
    }
  }
}
.export-csv-button {
  margin-left: 20px;
}

.timesheet-user-container > div > [class*='rct-header-root'] {
  background: var(--primary);
}
.timsheet-overview-calendar {
  max-width: fill-available;
  padding: 20px;
  background: white;
}
.timsheet-overview-calendar > div > [class*='rct-header-root'] {
  background: var(--primary);
}

@media only screen and (max-width: $iphone-width) {
  .timesheet-user-container {
    max-height: 700px;
    overflow-y: scroll;
  }
  .avatar-timesheet {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .vertical-timeline-element-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms;
    &:hover {
      transition: all 250ms;
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .vertical-timeline {
    margin: 0;
  }

  .vertical-timeline-element-content {
    margin-left: 80px;
    padding: 0;
  }
}
