@import '../../../styles//variables.scss';

.tbody {
  tr {
    border-bottom: 1px solid #e2e2e2;
    &:last-child {
      border-bottom: none;
    }

    td {
      &:first-child {
        padding-left: 24px;
      }

      &:nth-child(7) {
        white-space: nowrap;
      }

      &:last-child {
        width: 50px;
        text-align: right;
      }

      &.subComponentContainer {
        padding: 0;
      }
    }

    &.isExpanded {
      border-left: 2px solid blue;

      & + tr {
        border-left: 2px solid blue;
      }
    }
  }
}

.loadingBar {
  position: absolute;
  z-index: 5;
}

.icon {
  width: 14px;
}

.isHightLightGreen {
  background-color: #eef6f6;
}

.isHightLightBlue {
  background-color: #e6ffff;
}

.exportGroup {
  position: absolute;
  top: 4px;
  right: 180px;
  margin-bottom: 4px;
  button {
    background-color: var(--primary);
    border-color: var(--primary);
    cursor: pointer;
    color: #fff;
  }
  @media only screen and (max-width: $ipad-min-width) {
    top: 6px;
    right: 144px;
    margin-bottom: 0;
  }
}

.scrollAble {
  overflow: auto;
  width: calc(100% - 26px);
  background-color: white;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background: #aaaaaa;
  }
}
