.tagFormat {
  padding: 2px 8px !important;
  border-radius: 6px !important;
  text-align: center;
  width: 80px !important;
  display: inline-flex;
  white-space: nowrap;
  font-size: 14px !important;
  line-height: 20px;
  text-transform: capitalize;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  background: #f0fff4 !important;
  border: 1px solid #38a169 !important;
  color: #38a169 !important;
  height: 20px !important;
  font-weight: 500;
}

.tagsListFormat {
  padding: 8px;
  text-align: center;
  display: inline-flex;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border: 1px solid black;
  border-radius: 6px;
  color: black;
  margin: 2px;
}
