@import '../../../styles/_variables.scss';

.userContainer {
  position: relative;
  @media only screen and (max-width: $iphone-width) {
    & > div:first-of-type {
      button {
        font-size: 0;
      }
      button > span {
        font-size: 18px;
        margin-right: 0;
      }
    }
  }
}

.createButton {
  &:hover {
    background: #4d5dfb !important;
    opacity: 0.9;
    cursor: pointer;
  }
}
.filterButton {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
