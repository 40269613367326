.container {
  .selection {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .isDanger {
    color: #e53e3e !important;
  }

  .isWarning {
    color: #d69e2e !important;
  }

  .isSafe {
    color: #38a169 !important;
  }

  .groupButton {
    display: flex;
  }
  .groupButtonBackground {
    display: flex;
    padding: 10px;
    border-radius: 6px;
    background: #edf2f7;
  }
  .menu {
    right: 100%;
    // bottom: -100%;
    top: auto;
    left: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
  }
  @media all and (max-width: 768px) {
    .menu {
      min-width: 8rem;
    }
  }
  .noPadding {
    padding: 0;
  }

  button {
    color: var(--primary);
    &:hover {
      background-color: lightgrey;
    }
    &:active {
      background-color: lightgrey;
      outline: none;
      color: unset;
    }
  }
}
