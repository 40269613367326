.logoSection {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-height: fit-content;
}

.logoImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.headerBox {
  font-weight: bold;
  margin: 0px;
  width: 100%;

  &.border {
    border-top: 1px solid #e2e8f0;
  }
}
.avatarImage {
  max-width: 100%;
  max-height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}
