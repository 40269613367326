.profileAvatarButton {
  &:hover {
    background: #4d5dfb !important;
    cursor: pointer;
    opacity: 0.7;
  }
}

.formHeaderSection {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
}

.headerBox {
  font-weight: bold;
  padding: 10px 0px;
  margin-top: 1.5em !important;
  width: 100%;

  &.border {
    border-top: 1px solid #e2e8f0;
  }
}
