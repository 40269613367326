$blue: #007bff !default;
$blueSecondary: #4d5dfb !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$red-300: #fc8181 !default;
$red-400: #f56565 !default;
$red-500: #e53e3e !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$yellow-300: #f6e05e !default;
$yellow-500: #d69e2e !default;
$green: #28a745 !default;
$green-500: #38a169 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$gray: #f7fafc !default;
$white: #fff !default;

$border-radius: 0.25rem !default;
$card-bg: #fff !default;
$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$headings-font-weight: 500 !default;
$text-muted: #7e7e7e !default;
$font-size-base: 1rem !default;

$mobile-width: 576px;
$iphone-width: 767px;
$ipad-min-width: 768px;
$ipad-max-width: 1200px;

$user-primary-color: #1a365d;
$admin-primary-color: #2d3748;

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
}

@media only screen and (max-width: $iphone-width) {
}
