.logoSection {
  background-color: white;
  min-height: 300px;
  border-radius: 8px;
  padding: 20px;
}

.logoImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.headerBox {
  font-weight: bold;
  margin: 0px;
  width: 100%;

  &.border {
    border-top: 1px solid #e2e8f0;
  }
}
.avatarImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
