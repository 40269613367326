.cvSection {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.cvButton {
  text-align: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #2d3748;
  margin-right: 2px;
  &:hover {
    cursor: pointer;
  }
}
.svgIcon {
  vertical-align: -webkit-baseline-middle !important;
}
