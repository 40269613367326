@import '../../styles/_variables.scss';

@media screen and (max-width: $mobile-width) {
  .app-header {
    nav.myNavbar.collapsed {
      margin-left: 0;
    }
  }
}

.app-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 72px;
  max-height: 80px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

  .myNavbar {
    background-color: white;
    margin-left: 260px;
    max-height: 80px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

    &.collapsed {
      margin-left: 80px;
    }
  }

  .sidebar-toogle {
    align-self: center;
    color: #1a202c;
    float: left;
    height: 24px;
    margin-top: -4px;
    background-color: transparent;
    background-image: none;
    font-size: 24px;
    font-family: fontAwesome;

    transition: all 250ms;

    &:hover {
      transition: all 250ms;
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .myNavbar-custom-menu {
    max-width: 50%;
    display: flex;

    .myNavbar-nav {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
      }
    }
  }

  .user-menu-wrapper {
    color: #fff;
    font-size: 12px;
    display: block;

    & > div {
      cursor: pointer;
    }

    &.active > div {
      background: #edf2f7;
    }
  }

  .dropdown {
    font-family: 'Roboto';
    list-style: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    float: left;
    margin: 5px 12px;
    font-size: 16px;
    list-style: none;
    background-color: #ffffff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    padding: 0px !important;
    width: 209px;

    &.notification {
      width: 352px;

      .sender-name {
        font-weight: 600;
      }

      .notication-item {
        padding: 10px;

        &.un-read {
          background-color: #edf2fa;
        }

        &.header {
          color: #fff;
          background-color: var(--primary);
          text-align: center;
        }

        &.message {
          color: black;
        }

        &.content {
          display: flex;
          border-bottom: solid 1px #bdbdbd;
          color: black;

          .avatar {
            width: 30px;
            display: flex;
            align-items: center;
            margin: 0 10px 0 0;
          }

          .body {
            flex: 1;
          }

          img {
            max-width: 30px;
            height: 30px;
            border-radius: 100%;
            object-fit: cover;
          }

          p {
            margin: 0;
            margin-bottom: 5px;
            font-size: 13px;

            &.title {
              color: black;
              white-space: normal;
            }

            &.detail {
              color: black;
              font-size: 12px;
            }

            &.date {
              font-size: 12px;
            }
          }
        }

        &.link-to-read-all {
          color: #367fa9;
          text-decoration: underline;
          text-align: center;
        }
      }
    }

    .icon {
      margin-right: 8px;
      margin-top: 1px;
      width: 20px;
      height: 20px;
    }

    .profile {
      margin-top: 8px;

      a {
        width: 100%;
        text-decoration: none;
      }

      .profile-short {
        color: #2d3748;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 8px 16px;
        font-weight: 400;
        line-height: 24px;
        transition: all 250ms;
        margin: 0;

        .title-profile {
          font-size: 16px;
        }

        &:hover {
          transition: all 250ms;
          background-color: #e1e3e9;
          cursor: pointer;
        }
      }
    }

    .logout {
      margin-bottom: 8px;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    height: 50px;

    .avatar-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;

      img {
        max-width: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }

  .help-menu {
    color: #718096;

    .help-wrapper {
      width: 35px;
      display: flex;
      justify-content: center;
      padding: 12px 8px;
      cursor: pointer;
    }

    .help-icon {
      font-size: 18px;
    }
  }

  .alert-menu {
    color: #718096;

    span.alert-wrapper {
      width: 35px;
      display: block;
      padding: 16px 4px 8px 12px;
    }

    .number {
      position: absolute;
      top: 3px;
      right: 11px;
      background-color: #f0ad4e;
      border-radius: 3px;
      font-size: 9px;
      padding: 2px 4px;
    }

    .alert-icon {
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  header {
    .logo-mini {
      width: 260px;

      span {
        font-size: 27px;
        left: 54px;
      }
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  header {
    .help-menu {
      display: none;
    }

    .dropdown {
      width: 200px;

      .profile {
        min-height: 0;

        .profile-content {
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .app-header {
    width: 100vw;
    .dropdown {
      &.notification {
        width: 100vw;
        height: 100vh;
        top: 60px;
        //* INFO: 56px is width of avatar, 12px is margin-right of avatar
        right: calc(-56px - 12px);
        margin: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
}
