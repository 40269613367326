.uploadCVButton {
  &:hover {
    background: #4d5dfb !important;
    opacity: 0.7;
  }
}

.icon {
  padding: 0px 10px !important;
}
