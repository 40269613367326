.responsiveTable {
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    visibility: hidden;
    background: #fafafa;
    @media screen and (min-width: 45em) {
      visibility: visible;
    }
  }

  tr {
    border: 1px solid #ddd;
    border-radius: 5px;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
    display: block;
    @media screen and (min-width: 45em) {
      border-bottom-width: 1px;
    }
    &:nth-of-type(odd) td {
      background-color: #fafafa !important;
      &:nth-of-type(even) {
        background-color: white !important;
      }
    }
  }

  th,
  td {
    padding: 10px;
    text-align: right;
    height: 25px;
    .techSpan {
      padding: 5px;
      border-radius: 6px;
      text-align: center;
      font-size: 7px;
      line-height: 20px;
      font-weight: 400;
      text-transform: capitalize;
      margin: 5px;
    }
  }

  th {
    text-transform: uppercase;
    font-size: 11px;
  }

  td {
    display: block;
    text-align: right;
    font-size: 11px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 18px;
    }
    &:nth-of-type(odd) {
      background-color: #fafafa;
    }

    input {
      width: 50%;
    }
  }

  td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
}
