@import '../../../styles//variables.scss';
.thead {
  tr {
    th:first-child {
      padding-left: 24px;
    }
  }
}
.tbody {
  tr {
    border-bottom: 1px solid #e2e2e2;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      border-bottom: none;
    }

    td {
      &:first-child {
        padding-left: 24px;
      }

      &:nth-child(7) {
        white-space: nowrap;
      }

      &:last-child {
        width: 50px;
      }

      &.subComponentContainer {
        padding: 0;
      }
    }
  }
}

.loadingBar {
  position: absolute;
  z-index: 5;
}

.icon {
  width: 14px;
}

.isHightLightGreen {
  background-color: #eef6f6;
}

.isHightLightBlue {
  background-color: #e6ffff;
}

.scrollAble {
  overflow: auto;
  width: calc(100% - 26px);
  background-color: white;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background: #aaaaaa;
  }
}

.borderTable {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
}
