@import '../../styles/_variables.scss';

@media only screen and (max-width: $mobile-width) {
  div.main-sidebar {
    width: 100%;

    &.collapsed {
      left: -100%;
    }

    .collapse-button {
      display: flex;
    }
  }
}

.collapse-button {
  display: none;
  position: absolute;
  left: 10px;
}

.main-sidebar {
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 260px;
  z-index: 999;
  background-color: var(--primary);
  transition: all 300ms;

  &.collapsed {
    transition: all 300ms;
    width: 80px;
    .sidebar-menu {
      > .sidebar-header {
        transition: all 300ms;
        font-size: 0;
        padding: 8px;
        margin-top: 12px;
        height: 0;
        &:first-of-type {
          display: none;
        }
      }
      > .sidebar-item > a,
      > .sidebar-item > p {
        transition: all 300ms;
        font-size: 0;
        width: 24px;
        place-content: center;
        padding: 12px 16px;
        cursor: pointer;

        .unRead-number-alert {
          display: none;
        }
        .icon {
          transition: all 300ms;
          margin-right: 0;
          font-size: 24px;
          width: 24px;
          height: 24px;
        }
      }
      .sub-menu {
        transition: all 300ms;
        display: none;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  .sidebar {
    & > a:first-of-type {
      display: flex;
      height: 69px;
    }
    padding: 0px;
    .sidebar__logo {
      padding-bottom: 26px;
      display: block;
      margin: auto;
    }
    .sidebar-menu {
      list-style: none;
      padding: 0;
      font-size: 14px;
      overflow-y: hidden;
      height: calc(100vh - 80px - 48px);

      &:hover {
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }

      &::-webkit-scrollbar-thumb:vertical {
        border-radius: 50%;
        background: rgb(41, 41, 41);
      }
    }
    .sidebar-header {
      color: #a0aec0;
      padding: 24px 22px 10px 18px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;
      padding-left: 28px;
      border-top-width: 1px !important;
      border-top-style: solid !important;
      border-top-color: rgba(255, 255, 255, 0.24) !important;
      &:first-of-type {
        margin-top: 0;
        border-top: 0 !important;
        padding-top: 0;
      }
    }

    .sidebar-item {
      position: relative;
      &:hover {
        a,
        p {
          transition: all 250ms;
          background-color: rgba(255, 255, 255, 0.08);
          color: $gray;
          cursor: pointer;
        }
        .icon > path {
          stroke: $gray;
        }
      }
      &.active {
        a,
        p {
          transition: all 250ms;
          background-color: rgba(255, 255, 255, 0.16);
          color: $white;
          font-weight: 500;
          cursor: pointer;
        }
        .icon > path {
          stroke: $white;
        }
      }
      a,
      p {
        margin-left: 12px;
        margin-right: 12px;
        transition: all 250ms;
        position: relative;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: $gray;
        text-decoration: none;
        padding: 12px 16px;
        border-radius: 6px;
        cursor: pointer;
        .unRead-number-alert {
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          padding: 0 8px;
          font-size: 0.7em;
          background: green;
          color: white;
          text-align: center;
          max-height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 18px;
          border-radius: 50%;
          box-shadow: 0 0 1px #333;
        }
        .icon {
          margin-right: 12px;
          font-size: 20px;
          width: 20px;
          height: 20px;
          transition: all 300ms;
          > path {
            stroke: $gray;
          }
        }
        .arrowIcon {
          width: 20px;
          height: 100%;
          transition: all 300ms;
          transform: rotate(90deg);
          > path {
            fill: $gray;
            stroke: $gray;
          }
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .arrowIcon {
      position: absolute;
      top: 0;
      right: 20px;
      width: 20px;
      height: 100%;
      transition: all 250ms;
      transform: rotate(90deg);
      cursor: pointer;

      > path {
        fill: $gray;
        stroke: $gray;
      }
    }

    .rotateArrow {
      transition: all 250ms ease-in-out;
      transform: rotate(0deg);
    }

    .sub-menu {
      margin: 0;
      &:hover {
        a,
        p {
          transition: all 250ms;
          background-color: rgba(255, 255, 255, 0.08);
          color: $gray;
          cursor: pointer;
        }
      }
      &.active {
        a,
        p {
          transition: all 250ms;
          background-color: $blueSecondary;
          color: $white;
          font-weight: 500;
          cursor: pointer;
        }
      }
      a,
      p {
        transition: all 250ms;
        position: relative;
        display: flex;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: $gray;
        text-decoration: none;
        padding: 8px 0px 8px 46px;
        border-radius: 6px;
        margin: 8px 12px;
        cursor: pointer;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
