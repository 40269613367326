@import '../../../../styles/chakra-variables.scss';

.bi-diagram-link.user-active {
  > .bi-link-path {
    stroke: $blue500 !important;
  }
  .bi-diagram-link-label {
    background: $blue500 !important;
  }
}

.bi-diagram-link.project-active {
  > .bi-link-path {
    stroke: $orange500 !important;
  }
  .bi-diagram-link-label {
    background: $orange500 !important;
  }
}

.bi-diagram-link-label {
  background: $gray500 !important;
  border-radius: 6px !important;
  width: 64px;
  font-weight: 500;
  font-size: 12px !important;
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke: $gray400;
  stroke-width: 1px !important;
}

.node-user-active {
  button {
    background: $blue500;

    p {
      color: white;
      font-size: large;
    }
  }
}

.node-project-active {
  button {
    background: $orange500;

    p {
      color: white;
      font-size: large;
    }
  }
}

//INFO*: Library does not support zooming in/out and nodes' coordinations are between 0 and 3000
.bi.bi-diagram {
  min-width: 3000px;
  min-height: 3000px;
}
