.container {
  .selection {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .isDanger {
    color: #e53e3e;
  }

  .isWarning {
    color: #d69e2e;
  }

  .isSafe {
    color: #38a169;
  }

  .groupButton {
    display: flex;
    align-items: center;
    span {
      margin: 0;
    }
    a {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
  .groupButtonBackground {
    display: flex;
    padding: 12px;
    border-radius: 6px;
    background: #edf2f7;

    a {
      display: flex;
    }
  }
  .menu {
    right: 100%;
    // bottom: -100%;
    top: auto;
    left: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    width: fit-content;
  }
  @media all and (max-width: 768px) {
    .menu {
      min-width: 8rem;
    }
  }
  .noPadding {
    padding: 0;
  }

  button {
    color: var(--primary);
    &:hover {
      background-color: lightgrey;
    }
    &:active {
      background-color: lightgrey;
      outline: none;
      color: unset;
    }
  }
}
