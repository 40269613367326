@import 'src/styles/variables.scss';

.profileAvatarButton {
  &:hover {
    background: #4d5dfb !important;
    cursor: pointer;
    opacity: 0.7;
  }
}

.formHeaderSection {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
}

.headerBox {
  font-weight: bold;
  width: 100%;

  &.border {
    border-top: 1px solid #e2e8f0;
    padding-top: 24px;
  }
}
@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  .headerBox {
    padding-left: 10px;
    &.border {
      border-top: none;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .headerBox {
    padding-left: 10px;
    &.border {
      border-top: none;
      padding-top: 28px;
    }
  }
}
