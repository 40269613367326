.wrapper {
  width: 98% !important;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 10px;
}

.container {
  //INFO: using important to override editor default
  width: 100% !important;
  height: initial !important;
  min-height: 200px;
  color: #2b6cb0 !important;
  background-color: #f7fafc !important;

  [class*='ace_string'] {
    color: #2b6cb0 !important;
  }

  [class*='ace_paren'] {
    color: black !important;
  }

  [class*='ace_gutter'] {
    background-color: #e2e8f0 !important;
  }

  [class*='ace_print-margin'] {
    visibility: hidden !important;
  }
  * {
    font-family: monospace !important;
  }
}

.inputLabel {
  font-size: 12px !important;
}

.disabled {
  cursor: not-allowed;

  [class*='ace_content'] {
    cursor: not-allowed;
  }
}
