.marker {
  position: absolute;
  left: -28px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #cbd5e0;
  top: 16px;
}

.container > :first-child .marker {
  background-color: #3182ce;
}
