.admin,
.Archived {
  background: #fffff0;
  border: 1px solid #d69e2e !important;
  color: #d69e2e;
  text-transform: capitalize;
}

.user {
  background: #ebf8ff;
  border: 1px solid #3182ce !important;
  color: #3182ce;
  text-transform: capitalize;
}

.Active,
.Approved {
  background: #f0fff4;
  border: 1px solid #38a169 !important;
  color: #38a169;
}

.Deleted {
  border: 1px solid #673d3d !important;
  background: #fed7d7;
  color: #e53e3e;
}
