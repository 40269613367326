@import '../../styles/chakra-variables.scss';

.errorMessage {
  color: red;
  margin: 0;
}

.labelForm {
  font-weight: 400 !important;
  color: $gray700;
  &:hover {
    color: $cyan600;
  }
}

.inputField {
  color: $gray700;
  max-height: 40px;
}

.showPassword {
  max-height: 24px;
  cursor: pointer;
  color: $gray400;
}
