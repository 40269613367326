@import '../../../../styles/variables.scss';

#sidebar-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-style: unset;
  color: black;
}

.low {
  color: $yellow-500;
}

.stable {
  color: $green-500;
}

.high {
  color: $red-500;
}
