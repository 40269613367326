@import '../../../styles/_variables.scss';
@import '../../../styles/index.scss';

.leaveContainer {
  table tbody tr td {
    white-space: pre-line;
    span:empty {
      min-height: 40px;
      display: flex;
      pointer-events: none;
    }
  }
  position: relative;
  @media only screen and (max-width: $iphone-width) {
    & > div:first-of-type {
      button {
        font-size: 0;
      }
      button > span {
        font-size: 18px;
        margin-right: 0;
      }
    }
  }
}

.createButton {
  &:hover {
    background: $blueSecondary !important;
    opacity: 0.9;
    cursor: pointer;
  }
}
.filterButton {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.body {
  display: flex;
  align-items: center;
  color: $admin-primary-color;

  span {
    font-weight: 500;
  }
}

.Pending {
  background: red !important;
}

.textHover:hover {
  color: $blueSecondary;
  cursor: pointer;
}
