@import '../../../styles/_variables.scss';

.products-list-page {
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .item {
    margin: 10px 20px 10px 0;

    .item-content {
      width: 300px;
    }
  }
  .to-parameters-link {
    text-decoration: none;
    color: #5a5656;
    font-size: 18px;
    transition: all 250ms;
    &:hover {
      transition: all 250ms;
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .products-list-page {
    .items {
      display: block;
    }
    .item {
      margin: 10px;

      .item-content {
        width: 250px;
      }
    }
  }
}
