.tagColorCellOutside {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagColorCellInside {
  width: 22px;
  height: 22px;
  border-radius: 500%;
}
