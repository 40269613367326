.formHeaderSection {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
}

.section {
  width: 100%;
  background-color: white;
  border-radius: 8px;
}

.avatarSection {
  background-color: white;
  min-height: 300px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
}
.cvSection {
  background-color: white;
  min-height: 300px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.avatarImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.projectRatingInputField {
  border: none;
  background-color: transparent;
}
