.profileAvatarButton {
  &:hover {
    background: #4d5dfb !important;
    cursor: pointer;
    opacity: 0.7;
  }
}

.section {
  width: 100%;
  background-color: white;
  border-radius: 8px;
}

.avatarSection {
  background-color: white;
  min-height: 300px;
  border-radius: 8px;
  padding: 15px;
}
.cvSection {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.avatarAddButton {
  border: 1px solid #e2e8f0;
  border-radius: 100% !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #fff !important;
  box-sizing: border-box;

  position: absolute !important;
  right: 0%;
  top: 64.91%;
  bottom: 0%;
  &:hover {
    cursor: pointer;
  }
}
.avatarImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
.projectRatingInputField {
  border: none;
  background-color: transparent;
}
.cvButton {
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #2d3748;
  margin-right: 2px;
  &:hover {
    cursor: pointer;
  }
}

.editButton {
  &:hover {
    background: #4d5dfb !important;
    opacity: 0.9;
    cursor: pointer;
  }
}

.svgIcon {
  vertical-align: -webkit-baseline-middle !important;
}

.form {
  width: 100%;
}

.inputFieldOnTable {
  border: none;
  background-color: transparent;
}

.headerBox {
  font-weight: bold;
  padding: 10px 0px;
  margin: auto 0;
  margin-top: 1.5em !important;
  width: 100%;

  &.border {
    border-top: 1px solid #e2e8f0;
  }
}
