@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_root.scss';
@import '~bootstrap/scss/_dropdown.scss';
@import './_boxes.scss';
@import './_variables.scss';

body {
  height: 100%;
  font-family: 'Roboto';
  font-weight: 400;
  margin: 0;
}

* > * {
  font-family: 'Roboto';
}

:root {
  --primary: #2d3748;
}

button {
  box-shadow: none;
  border: 1px solid transparent;
  padding: 5px 15px;
  font-size: 16px;
  line-height: 1.3333333;
  color: #fff;
  border-radius: 3px;
}
.primary-button {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.secondary-button {
  background-color: rgb(225, 0, 80);
  box-shadow: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.third-button {
  background-color: #4caf50;
  box-shadow: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.fourth-button {
  background-color: rgb(225, 197, 0);
  box-shadow: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.fifth-button {
  background-color: orange;
  box-shadow: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.sixth-button {
  background-color: purple;
  box-shadow: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.container {
  .container-title {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }
  .select-group {
    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }
    select {
      border-radius: 0;
      box-shadow: none;
      border-color: #d2d6de;
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      &:focus {
        outline: none;
      }
    }
    &.select-month-year {
      input {
        display: block;
        width: 100%;
        height: 20px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      }
    }
  }
}
.box-header {
  .logo-sub-header {
    margin-right: 20px;
    img {
      max-width: 30px;
      max-height: 30px;
    }
  }
}
form {
  .fields {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .logo-sub {
      width: 300px;
      margin: auto;
      margin-bottom: 20px;
      text-align: center;
      img {
        max-width: 300px;
      }
    }
    &.logo {
      display: block;
    }
    .avatar-sub {
      height: 150px;
      width: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      padding: 20px;
      img {
        max-height: 150px;
        max-width: 150px;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &.avatar {
      display: block;
      text-align: center;
    }
  }
  .field {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;

    &.radio-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label-autocomplete-select {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 1;
    }
    .creatable-select {
      > [class*='control'] {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        box-shadow: unset;
      }
    }
  }
  .error-message {
    display: block;
    color: $red;
    font-size: 14px;
    margin-top: 10px;
    font-style: italic;
  }
  .input-field {
    margin: 10px 0;
    width: 100%;
  }
  .select-field {
    width: 100%;
    margin: 10px 0 !important;
  }
}
.timeline-container {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 31px;
    margin: 0;
    border-radius: 2px;
  }
  div {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
    &::before {
      content: ' ';
      display: table;
    }
  }
  .timeline-label {
    span {
      font-weight: 400;
      padding: 5px;
      display: inline-block;
      color: #fff;
      border-radius: 4px;
      background-color: #00a65a !important;
    }
  }
  .timeline-item {
    .icon-sub {
      width: 30px;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      position: absolute;
      color: #fff;
      background: #d2d6de;
      border-radius: 50%;
      text-align: center;
      left: 18px;
      top: 0;
      background-color: #0073b7 !important;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .timeline-content {
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      margin-top: 0;
      background: #fff;
      color: #444;
      margin-left: 60px;
      margin-right: 15px;
      padding: 0;
      position: relative;
      .time {
        color: #999;
        float: right;
        padding: 10px;
        font-size: 12px;
      }
      .timeline-header {
        margin: 0;
        color: #555;
        border-bottom: 1px solid #f4f4f4;
        padding: 10px;
        font-size: 16px;
        line-height: 1.1;
      }
      .timeline-body {
        padding: 10px;
      }
    }
  }
}

.confirm-dialog {
  font-size: 14px;
  .warning-message {
    background-color: #fffbdd;
    color: #735c0f;
    border-radius: 0;
    border-width: 1px 0;
    margin-top: -1px;
    border: 1px solid rgba(27, 31, 35, 0.15);
    padding: 16px;
    position: relative;
  }
  .confirm-message {
    .highlight {
      font-weight: 600;
    }
  }
  .confirm-error-message {
    display: block;
    color: $red;
    font-size: 14px;
    margin: 5px 0;
    font-style: italic;
  }

  .confirm-warning-message {
    display: block;
    color: rgb(255, 171, 0);
    font-size: 14px;
    margin: 5px 0;
    font-style: italic;
  }
  .delete-input-field {
    background-color: #fff;
    background-position: right 8px center;
    background-repeat: no-repeat;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075);
    color: #24292e;
    font-size: 16px;
    line-height: 20px;
    min-height: 25px;
    outline: none;
    padding: 6px 8px;
    width: 95%;
    vertical-align: middle;
  }
  .confirm-message {
    padding: 0 16px;
  }
  .confirm-delete-again {
    padding: 0 16px 20px 16px;
  }
  .field {
    display: flex;
    margin-bottom: 20px;
    label {
      width: 80px;
      font-weight: 600;
    }
    input {
      flex: 1;
    }
  }
}
.radio_label {
  height: 100%;
}
.highlight {
  font-weight: 600;
}
.rct-sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar-timeline .rct-sidebar-header {
  background: var(--primary);
}

.react-calendar-timeline .rct-header .rct-label-group {
  background: var(--primary);
}
.icon-with-text__wrapper {
  div {
    min-width: 24px !important;
  }
}

.noMarginBottom {
  margin-bottom: 0 !important;
  > div {
    align-self: center;
  }
}
.input-file-container {
  position: relative;
  width: 225px;
  margin: auto;
}
.input-file-trigger {
  display: block;
  padding: 7px 15px;
  background: var(--primary);
  color: #fff;
  font-size: 1em;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
.timesheet-page {
  .timesheet-content {
    margin-top: 50px;
  }
  .table-content {
    width: 100%;
    overflow: scroll;
  }
  .report-table {
    margin-top: 20px;
    width: 100%;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: separate;
    clear: both;
    margin-top: 6px;
    margin-bottom: 6px;
    max-width: none;
    border: 1px solid #f4f4f4;
    overflow: hidden;
    thead tr th {
      border-bottom-width: 2px;
      border: 1px solid #f4f4f4;
      vertical-align: bottom;
      padding: 8px;
      line-height: 1.42857143;
    }
    tbody tr td {
      border-left-width: 0;
      border-bottom-width: 0;
      border: 1px solid #f4f4f4;
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: center;
      box-sizing: content-box;
      .options {
        text-align: center;
      }
      a.task-link {
        color: rgba(0, 0, 0, 0.87);
      }
      &.options {
        text-align: center;
      }
      &.description {
        white-space: pre-line;
        width: 30%;
      }

      &.project-name {
        .editted-info {
          font-weight: 600;
          &.user {
            color: rgb(225, 0, 80);
          }
          &.admin {
            color: #ffbb00;
          }
        }
      }
      &.status {
        font-weight: 600;
        &.active {
          color: #4fad33;
        }
        &.disable {
          color: #777777;
        }
      }
      &.taskname {
        .editted-info {
          font-weight: 600;
          &.user {
            color: rgb(225, 0, 80);
          }
          &.admin {
            color: #ffbb00;
          }
        }
      }

      &.commit-time-status {
        font-weight: 600;
        &.approved {
          color: #4fad33;
        }
        &.rejected {
          color: rgb(225, 0, 80);
        }
      }
    }
  }

  tbody tr {
    transition: all 250ms;
    &:hover {
      transition: all 250ms;
      background-color: #e7e4e4;
    }
    &.odd {
      background-color: #f9f9f9;
      &:hover {
        background-color: #e7e4e4;
      }
    }
    .duration {
      font-weight: 600;
      &.normal {
        color: #4fad33;
      }
      &.warning {
        color: #ffbb00;
      }
      &.emergency {
        color: rgb(225, 0, 80);
      }
    }
    .remuneration-per-hours {
      font-weight: 600;
      &.normal {
        color: #4fad33;
      }
      &.error {
        color: rgb(225, 0, 80);
      }
    }
  }
}

.pagination-cv-page {
  text-align: center;
  .pagination-sub {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-button {
      margin: 0 5px;
      padding: 1px 6px;
      background-color: var(--primary);
      border-radius: 3px;
      color: #fff;
      .double-arrow {
        font-size: 22px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
      &.disabled {
        display: none;
      }
    }
  }
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
  &.headericons {
    margin-top: -12px;
  }
  &.float-right {
    float: right !important;
  }
  .button-group {
    display: flex;
  }
  .list-inline-item {
    display: inline-block;
    margin-right: 1.5rem;
    height: 0.7rem;
    width: 0.7rem;
  }
}

.react-pdf__Document {
  width: 100% !important;
  overflow-x: scroll;
}

.react-pdf__Page__canvas {
  width: 100% !important;
}

.show-desktop {
  display: block;
}

.show-sp {
  display: none;
}

.u-line-through {
  text-decoration: line-through;
}

body.dragging * {
  cursor: grabbing !important;
  user-select: none;
}

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  form {
    .fields {
      display: block;
    }
    .field {
      max-width: 100%;
      &.radio-button {
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  button {
    font-size: 13px;
    padding: 5px 10px;
  }
  .timeline-container {
    div {
      margin-right: 0;
    }
  }
  form {
    .fields {
      display: block;
      .logo-sub {
        width: 200px;
        img {
          max-width: 200px;
        }
      }
      .avatar-sub {
        height: 100px;
        width: 100px;
        img {
          height: 100px;
          width: 100px;
        }
      }
    }
    .field {
      max-width: 100%;
      &.radio-button {
        justify-content: flex-start;
      }
    }
  }
  .react-pdf__Page__canvas {
    width: 595px !important;
    height: 842p !important;
  }
  ul.list-unstyled {
    &.float-right {
      &.breakline {
        float: none !important;
        margin-top: 10px;
      }
    }

    .list-inline-item {
      display: inline-block;
      margin-right: 1.5rem;
      height: 0.7rem;
      width: 0.7rem;
    }
  }

  .show-desktop {
    display: none;
  }

  .show-sp {
    display: block;
  }
}

.break-line {
  width: 100%;
  height: 1px;
  border-top: dashed;
  border-color: var(--primary);
}

.payment-page-header {
  margin-bottom: 10px !important;
}

.blue-color-text {
  color: #3182ce;
}

.yellow-color-text {
  color: #d69e2e;
}

.green-color-text {
  color: #66bb6a;
}

.red-color-text {
  color: #dc3545;
}

form.full-form {
  width: 100%;
  input[type='date']:hover {
    cursor: pointer;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.can-hover {
  &:hover {
    cursor: pointer;
  }
}

.late-logging-time-status {
  font-weight: 500;
  &.normal {
    color: #4fad33;
  }
  &.warning {
    color: #ffbb00;
  }
  &.emergency {
    color: rgb(225, 0, 80);
  }
}

.duration {
  font-weight: 500;
  &.normal {
    color: #4fad33;
  }
  &.warning {
    color: #ffbb00;
  }
  &.emergency {
    color: rgb(225, 0, 80);
  }
}

.task-description {
  .ql-container {
    padding: 0;

    .ql-editor {
      padding: 0;
      resize: unset;
      min-height: -webkit-fit-content;
      ul {
        padding: 0 !important;
      }
      ol {
        padding: 0 !important;
      }
    }

    .ql-tooltip {
      height: 0;
    }
  }
}

.text-align-center {
  text-align: center;
}
