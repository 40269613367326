@import 'src/styles/variables.scss';
.profileAvatarButton {
  &:hover {
    background: #4d5dfb !important;
    cursor: pointer;
    opacity: 0.7;
  }
}

.formHeaderSection {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
}

.headerBox {
  font-weight: bold;
  margin: 0px;
  padding-bottom: 10px;
}

.groupInformation {
  width: 100%;
  &.border {
    border-bottom: 1px solid #e2e8f0;
  }
}

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  .groupInformation {
    padding-left: 10px;
    padding-top: 28px;
    .headerBox {
      padding-left: 10px;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .groupInformation {
    padding-left: 10px;
    padding-top: 28px;
    .headerBox {
      padding-left: 10px;
      border-bottom: none;
    }
  }
}
