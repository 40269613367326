.not-found-page {
  align-items: flex-start !important;
  padding: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  background-position: center;
  .not-found-content {
    a {
      color: #4d5dfb;
      font-style: italic;
      text-decoration: none;
      font-size: xx-large;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}
