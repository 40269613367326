@import '../../styles/chakra-variables.scss';
@import '../../styles/_variables.scss';

.container {
  height: 100%;

  [class*='fc-view-container'] {
    width: 100%;
    min-width: $iphone-width;
  }

  [class*='fc-scroller'] {
    max-height: calc(70vh);
    overflow-y: scroll !important;
  }
  [class*='fc-scroller']::-webkit-scrollbar {
    display: none;
  }

  [class*='fc-day-header'] {
    color: $gray500 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    background: $gray50 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  [class*='fc-now-indicator-arrow'] {
    border-width: 8px 0 8px 10px !important;
  }

  //* INFO: styling for day grid month view
  [class*='fc-dayGridMonth-view'] {
    [class*='fc-widget-content'] {
      min-height: 100px !important;
    }

    div[class*='fc-widget-header'] {
      border-radius: 6px 0px 0px 0px;
    }

    [class*='fc-event'] {
      margin-bottom: 8px;
      padding: 0 4px;
    }

    [class*='fc-content'] {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    [class*='fc-other-month'] {
      color: $gray400;
    }

    [class*='fc-day-grid-event'] {
      background-color: white;
      border: none;
      cursor: pointer;
    }

    [class*='fc-title'] {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $gray700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }

    [class*='fc-today'] {
      span {
        background-color: #08c8f6;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
  }

  //* INFO: styling for time grid week view
  [class*='fc-timeGridWeek-view'] {
    [class*='fc-day-header'] {
      &[class*='fc-today'] {
        background-color: #08c8f6 !important;
        p,
        b {
          color: $gray200 !important;
        }
      }
      [class*='custom-header-content'] {
        p {
          margin-bottom: 4px;
          margin-top: 0px;
        }
        b {
          color: $gray800;
          font-size: 16px;
        }
      }
    }

    [class*='fc-time-grid-event'] {
      padding: 0 12px;
    }

    [class*='fc-event'] {
      border: none;
    }

    [class*='fc-content'] {
      display: flex;
      flex-direction: column-reverse;
    }

    [class*='fc-time'],
    [class*='fc-title'] {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    [class*='fc-title'] {
      color: $gray700;
      font-size: 15px !important;
      font-weight: 500;
      line-height: 20px;
    }

    [class*='fc-time'] {
      color: $gray700;
      font-size: 13px;
      line-height: 16px;
    }
  }

  //* INFO: styling for time grid day view
  [class*='fc-timeGridDay-view'] {
    [class*='fc-widget-content'] {
      height: 48px;
    }

    [class*='fc-head'] {
      display: none;
    }

    [class*='fc-content'] {
      display: flex;
      flex-direction: column-reverse;
    }

    [class*='fc-axis'] {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray700;
    }

    [class*='fc-event'] {
      padding: 0px 12px;
      border: none;
      width: 100%;
    }

    [class*='fc-title'] {
      color: $gray700;
      font-size: 15px !important;
      font-weight: 500;
      line-height: 20px;
    }

    [class*='fc-time'] {
      color: $gray700;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
