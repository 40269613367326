@import '../../styles/_variables.scss';

.app-wrapper {
  position: relative;
  min-height: calc(100vh - 72px);
  background-color: #edf2f7;
  z-index: 800;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 260px;
  padding-top: 72px;
  &.collapsed {
    margin-left: 80px;
  }
  .app-content-wrapper {
    padding: 24px;
  }
}

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  .app-wrapper {
    .app-content-wrapper {
      padding: 20px;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .app-wrapper {
    &.collapsed {
      .app-content-wrapper {
        display: block;
        padding: 20px;
        //TODO: remove for timesheet UX ver1, may use later
        // overflow-x: auto;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .app-wrapper {
    margin-left: 0;

    &.collapsed {
      margin-left: 0;

      .app-content-wrapper {
        padding: 0;
      }
    }
  }
}
