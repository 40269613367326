@import '../../styles/_variables.scss';

.internalDocument-list-page-admin {
  .link_to_internalDocument_list {
    a {
      font-style: italic;
      text-decoration: none;
      font-weight: 600;
      color: #136899;
    }
    transition: all 250ms;
    &:hover {
      transition: all 250ms;
      opacity: 0.7;
    }
  }
  .sub-title {
    font-style: italic;
    text-decoration: none;
    font-weight: 600;
    color: #136899;
  }
  .options-sub {
    display: flex;
    align-items: center;
    .create-new-internalDocument-button {
      font-size: 14px;
    }
    .create-new-internalDocument {
      flex: 1;
    }
    .select-group {
      flex: 1;
    }
  }
  .internalDocument-detail-sub {
    flex: 3;
    margin-left: 10px;
  }
  .internalDocument-list-wrapper {
    flex: 1;
    background-color: #fff;
    border-radius: 3px;
    padding: 10px;
    max-height: 573px;
    overflow-y: auto;

    .item {
      display: flex;
      justify-content: space-between;
      border: solid 1px rgba(0, 0, 0, 0.08);
      padding: 5px;
      margin: 5px 0;
      border-radius: 3px;
      &:hover {
        background-color: #ecf0f5;
        cursor: pointer;
      }
      &.active {
        background-color: #ecf0f5;
      }
      .item-head {
        display: flex;
        justify-content: center;
        align-items: center;

        .internalDocument-avatar {
          height: 30px;
          width: 30px;
          border-radius: 100%;
          margin-right: 5px;
          object-fit: cover;
        }
        .name {
          display: inline-block;
          margin-left: 3px;
        }
      }
    }
  }
  .content-sub {
    display: flex;
    margin-top: 20px;
  }
  .suprvisorment-permission {
    .options-list {
      display: flex;
      flex-wrap: wrap;
      div {
        flex-basis: 50%;
      }
    }
  }
}
.internalDocument-create-page {
  .options-sub {
    margin-top: 20px;
  }
}

@media only screen and (min-width: $ipad-min-width) and (max-width: $ipad-max-width) {
  .internalDocument-list-page-admin {
    .internalDocument-detail-sub {
      flex: 3;
      margin-left: 0;
    }
    .content-sub {
      display: block;
      margin-bottom: 20px;
    }
    .internalDocument-list-wrapper {
      margin-bottom: 20px;
      max-height: 350px;
    }
  }
}

@media only screen and (max-width: $iphone-width) {
  .internalDocument-list-page-admin {
    .internalDocument-detail-sub {
      flex: 3;
      margin-left: 0;
    }
    .content-sub {
      display: block;
      margin-bottom: 20px;
    }
    .internalDocument-list-wrapper {
      margin-bottom: 20px;
      max-height: 350px;
    }
    .suprvisorment-permission {
      .options-list {
        display: block;
      }
    }
  }
}
