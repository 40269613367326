$gray50: #f7fafc;
$gray200: #e2e8f0;
$gray400: #a0aec0;
$gray500: #718096;
$gray600: #718096;
$gray700: #2d3748;
$gray800: #1a202c;
$cyan600: #319795;
$purple600: #2a4365;
$blue: #4d5dfb;
$white: #ffffff;

$blue500: #3182ce;
$orange500: #dd6b20;
