@import '../../../styles/_variables.scss';
@import '../../../styles/index.scss';

.leaveContainer {
  position: relative;
  @media only screen and (max-width: $iphone-width) {
    & > div:first-of-type {
      button {
        font-size: 0;
      }
      button > span {
        font-size: 18px;
        margin-right: 0;
      }
    }
  }
}

.createButton {
  &:hover {
    background: #4d5dfb !important;
    opacity: 0.9;
    cursor: pointer;
  }
}
.filterButton {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.body {
  display: flex;
  align-items: center;
  color: $admin-primary-color;

  span {
    font-weight: 500;
  }
}

.Pending {
  background: red !important;
}

.wrapTextInMobile {
  @media only screen and (max-width: $iphone-width) {
    flex-direction: column;
    align-items: start !important;
  }
}

.paddingScrollModalBody {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.textHover:hover {
  color: $blueSecondary;
  cursor: pointer;
}
