.profileAvatarButton {
  &:hover {
    background: #4d5dfb !important;
    cursor: pointer;
    opacity: 0.7;
  }
}
.formHeaderSection {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
}
