$time-picker-border: #e2e8f0;
$error-border: #e53e3e;

#picker-popover {
  z-index: 1500;
}

.custom-time-picker {
  border: 1px solid $time-picker-border !important;
  border-radius: 6px;

  > div {
    padding-left: 16px;
    height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;

    > input {
      padding: 0;
    }
  }

  :after {
    border: none !important;
  }

  :before {
    border: none !important;
  }
}

.textarea {
  border: 1px solid $time-picker-border;
  border-radius: 6px;

  > div:first-of-type {
    border: none;
    border-bottom: 1px solid $time-picker-border;
    padding: 4px 8px;
    height: 40px;

    + div {
      border: none;

      > div {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  > .ql-container > .ql-editor.ql-blank::before {
    color: #a0aec0;
    padding-left: 1px;
    font-style: normal;
  }
}

.textarea.focused {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

.ql-editor {
  min-height: 127px;
  resize: vertical;
  font-size: 14px;
}

.chakra-modal__content-container > section {
  height: 90%;
  overflow: auto;
}

#timeTotal-feedback {
  position: absolute;
}

.description-error {
  border-color: $error-border;
  box-shadow: 0 0 0 1px $error-border;
}
